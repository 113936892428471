import $ from 'jquery';
import Component from '../models/Component';

export default class Tabs extends Component {
 constructor(element, context) {
  super(element, context);
  $(() => {
    $('.tab-child').not(':first').hide();
    $('.tab').click(function () {
     $('.tab-child').hide();
     $('.tab-child[data-tab=' + $(this).data('tab') + ']').show();
     $('.tab').removeClass('active');
     $(this).addClass('active');
    });
  });
 }
}