import { gsapSingleton, scrollTriggerSingleton } from "../gsap";
const gsap = gsapSingleton();
const ScrollTrigger = scrollTriggerSingleton();
import $ from 'jquery';
import Component from '../models/Component';

export default class LineaAnimazione extends Component {
 constructor(element, context) {
  super(element, context);
  $(() => {
    gsap.fromTo(
    element, 
    {
        width: 0,
    }, 
    {
        ease: 'ease-in-out',
        width: 128,
        duration: 0.3,
        scrollTrigger: {
            trigger: element,
            start: 'top 90%',
        }
    });
  });
 }
}