import Registry from "./models/Registry";
import Accordion from "./components/Accordion"
import VimeoEmbed from "./components/VimeoEmbed";
import LineaAnimazione from "./components/LineaAnimazione";
import './components/Fadein';
import './components/MainMenu';
import Tabs from './components/Tabs';

class App {
  constructor(context) {
    this.registry = new Registry();
    this.setupUnloadScroll();
    this.registerComponents();
    this.initController(context);
    this.patchIos();
    this.setvh();
  }

  initController(contextData) {
    const Context = this.registry.getContext(contextData);
    new Context(contextData);
  }

  registerComponents() {
    this.registry
      .addComponent('Accordion', Accordion)
      .addComponent('VimeoEmbed', VimeoEmbed)
      .addComponent('LineaAnimazione', LineaAnimazione)
      .addComponent('Tabs', Tabs)
    ;
  }

  patchIos() {
    window.addEventListener('resize', () => this.appHeight())
    this.appHeight()
  }

  appHeight() {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }

  setvh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    // window.addEventListener('resize', () => {
    //   // We execute the same script as before
    //   let vh = window.innerHeight * 0.01;
    //   document.documentElement.style.setProperty('--vh', `${vh}px`);
    // });
  }
  
  setupUnloadScroll() {
    setTimeout(function() {

      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
        if (targetElement) {
          targetElement.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, 400);
  }
  
}

window.AppInit = function(context) {
    new App(context);
};
